import { Disclosure, Transition } from "@headlessui/react";
import { ChevronUpIcon } from "@heroicons/react/24/outline";
import React from "react";

export default function Accordion(props) {
  const { defaultOpen, title, counter, disabled, children, bgWhite, forceHeight, border, className } = props;

  return (
    <Disclosure as="div" defaultOpen={defaultOpen} className={`border-b last:border-none ${className}`}>
      {({ open }) => (
        <>
          <Disclosure.Button
            disabled={disabled}
            className={`flex w-full justify-between px-10 py-4 text-left text-sm font-medium text-slate-600 !outline-none !ring-0 transition-all ease-in-out duration-300 
            ${bgWhite ? "!bg-white" : ""}
            ${forceHeight ? "!h-12" : ""}
            ${border ? "border-b" : ""}
            ${open ? (disabled ? "bg-slate-50" : "bg-slate-100") : "hover:bg-slate-50"}
            `}
          >
            <span className="w-full flex justify-between">
              {title && (
                <>
                  {title} {!!counter && <span className="bg-gray-300 w-5 text-xs aspect-square rounded-full inline-flex items-center justify-center text-white">{counter}</span>}
                </>
              )}
            </span>
            {!disabled && (
              <span className="ml-4">
                <ChevronUpIcon className={`transition-all ease-in-out duration-300 transform rotate-180 ${open ? "rotate-0" : ""} h-5 w-5 text-slate-600`} />
              </span>
            )}
          </Disclosure.Button>
          <Transition
            enter="transition duration-100 ease-out"
            enterFrom="transform scale-95 opacity-0"
            enterTo="transform scale-100 opacity-100"
            leave="transition duration-75 ease-out"
            leaveFrom="transform scale-100 opacity-100"
            leaveTo="transform scale-95 opacity-0"
          >
            <Disclosure.Panel className={`px-5 text-sm text-gray-500 ${bgWhite ? "bg-white" : "bg-slate-50"} z-[99]`}>{children}</Disclosure.Panel>
          </Transition>
        </>
      )}
    </Disclosure>
  );
}
