import { Content, Footer, Header, Struture } from "app/components/atoms/modal";
import { useEffect, useState } from "react";
import { Tabs } from "../../../atoms/modal";
import { i18n } from "app/i18n";
import DropzoneInput from "app/components/atoms/inputs/dropzone";
import * as XLSX from "xlsx";

export default function ModalUpload(props) {
  const { isOpen = false, isLoading, onSubmit, onClose = () => {} } = props;

  // VARS
  const multiValueFields = ["Galeria", "Categoria"];
  const requiredFields = ["Nome Entidade", "Nivel", "Categoria"];
  const allTabs = [{ name: i18n("label.upload") }, { name: i18n("label.list") }];
  const fields = [
    { key: "Nome Entidade", field: "translation.title" },
    { key: "Nivel", field: "translation.level" },
    { key: "Telefone", field: "phonenumber" },
    { key: "Email", field: "email" },
    { key: "Descricao", field: "translation.description" },
    { key: "Website", field: "channels.website" },
    { key: "Facebook", field: "channels.facebook" },
    { key: "Instagram", field: "channels.instagram" },
    { key: "AirBnb", field: "channels.airbnb" },
    { key: "TripAdvisor", field: "channels.tripadvisor" },
    { key: "Booking", field: "channels.booking" },
    { key: "TheFork", field: "channels.thefork" },
    { key: "Morada", field: "" },
    { key: "Concelho", field: "place" },
    { key: "Link Google Maps Morada", field: "address" },
    { key: "Imagem de Logo", field: "image" },
    { key: "Galeria", field: "translation.gallery" },
    { key: "Video", field: "translation.video" },
    { key: "Categoria", field: "categories" },
  ];

  // STATE
  const [info, setInfo] = useState(null);
  const [tabActive, setTabActive] = useState(0);

  useEffect(() => {
    if (!isOpen) {
      setTabActive(0);
    }
  }, [isOpen]);

  function cleanCSV(string) {
    return string
      .replace(/("[\s\S]*?")/g, (m, cg) => cg.replace(/\n/g, "LINE-BREAK-TO-PRESERVE"))
      .split("\n")
      .filter((i) => Boolean(i.trim()))
      .map((i) => i.replace(/LINE-BREAK-TO-PRESERVE/g, "\n"));
  }

  function getHeaderAndLines(obj) {
    if (!obj?.length) return null;
    const delimiter = "|";
    const info = { headers: [], lines: [] };
    const lines = cleanCSV(obj);
    info.headers = lines[0].split(delimiter);
    lines.shift();
    for (let i = 0; i < lines.length; i++) {
      const line = lines[i];
      const parts = line.split(delimiter);
      const lineObject = parts.reduce((acc, part, index) => {
        if (multiValueFields.includes(info.headers[index])) acc[info.headers[index]] = part.split(";").filter(Boolean);
        else acc[info.headers[index]] = part;
        return acc;
      }, {});
      info.lines.push(lineObject);
    }
    return info;
  }

  function onChange(files) {
    const delimiter = "|";
    const reader = new FileReader();
    reader.onload = (e) => {
      const workbook = XLSX.read(e.target.result, { type: "binary" });
      const sheetName = workbook.SheetNames[0];
      const worksheet = workbook.Sheets[sheetName];
      const data = XLSX.utils.sheet_to_csv(worksheet, { header: 1, FS: delimiter });
      const dataStructure = getHeaderAndLines(data);
      if (!dataStructure) {
        setInfo(null);
        return null;
      }
      setInfo(dataStructure);
      setTabActive(1);
    };
    reader.readAsBinaryString(files[0]);
  }

  function isValidLine(line) {
    return requiredFields.every((field) => line[field] !== undefined && line[field] !== "");
  }

  function prepareLines(line) {
    let obj = {};

    for (let i = 0; i < fields.length; i++) {
      const key = fields[i].key;
      const field = fields[i].field;
      if (line[key]) {
        if (field.includes(".")) {
          const nested = field.split(".");
          let tempObj = obj;
          for (let j = 0; j < nested.length - 1; j++) {
            const current = nested[j];
            if (!tempObj[current]) tempObj[current] = {};
            tempObj = tempObj[current];
          }
          tempObj[nested[nested.length - 1]] = line[key];
        } else {
          obj[field] = line[key];
        }
      }
    }
    return obj;
  }

  function onClickSubmit() {
    const goodLines = info?.lines?.filter(isValidLine);
    if (!goodLines?.length) return null;
    onSubmit(goodLines.map(prepareLines));
  }

  return (
    <Struture isOpen={isOpen} onClose={onClose}>
      <Header title={i18n("label.upload_list")} onClose={onClose} />
      <Tabs tabs={allTabs} active={tabActive} onChange={setTabActive} />
      <Content tabs tab={tabActive}>
        <div>
          <DropzoneInput multi={false} onChange={onChange} formats={["EXCEL"]} isLoading={isLoading} disabled={isLoading} />
        </div>
        <div>
          {info && (
            <>
              <div className="overflow-x-scroll overflow-y-auto max-h-60 pb-2">
                <table>
                  <thead>
                    <tr>
                      <th className="border"></th>
                      {info.headers.map((e, key) => (
                        <th key={key} className="whitespace-nowrap pl-1 pr-3 border">
                          {e} {requiredFields.includes(e) && <span className="text-red-600">*</span>}
                        </th>
                      ))}
                    </tr>
                  </thead>
                  <tbody>
                    {info.lines.map((e, key) => (
                      <tr key={key} className="whitespace-nowrap">
                        <td className={isValidLine(e) ? "bg-green-300" : "bg-red-400"}></td>
                        {Object.values(e).map((v, key) => (
                          <td key={key} className="pl-1 pr-3 border">
                            {v.toString()}
                          </td>
                        ))}
                      </tr>
                    ))}
                  </tbody>
                </table>
              </div>
              <div className="mt-2">
                <p className="text-sm">{info?.lines?.filter(isValidLine).length || 0} linhas</p>
                <p className="text-xs text-red-500">{info?.lines?.filter((e) => !isValidLine(e)).length || 0} linhas inválidas</p>
              </div>
            </>
          )}
        </div>
      </Content>
      <Footer
        loading={isLoading}
        dynamic={tabActive < allTabs?.length - 1}
        cancel={{ text: i18n("button.close"), disabled: isLoading, onClick: onClose }}
        submit={{ text: i18n("button.submit"), disabled: isLoading, onClick: onClickSubmit }}
        next={{ text: i18n("button.next"), disabled: isLoading, onClick: () => setTabActive(tabActive + 1) }}
      />
    </Struture>
  );
}
