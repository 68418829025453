import { useState } from "react";
import { useCurrentEditor } from "@tiptap/react";

// ICONS
import { getSource } from "app/utils/content";
import ModalMedia from "app/components/molecules/modals/modalMedia";
import ModalLink from "app/components/molecules/modals/modalLink";
import validator from "validator";
import { Menu, Popover, Transition } from "@headlessui/react";
import { ChevronDownIcon } from "@heroicons/react/24/outline";
import { Fragment } from "react";
import { Portal } from "@headlessui/react";

export default function MenuBar(props) {
  const { modules } = props;

  // STATE
  const [modal, setModal] = useState(null);
  const [linkModal, setLinkModal] = useState(null);

  const [hoveredRows, setHoveredRows] = useState(0);
  const [hoveredCols, setHoveredCols] = useState(0);

  const { editor } = useCurrentEditor();
  if (!editor) return null;

  const addImage = () => {
    setModal({
      isOpen: true,
      withAlt: true,
      withTitle: true,
      onClose: () => setModal(null),
      onSubmit: (item) => {
        const src = getSource(item.path);
        if (src) editor.chain().focus().setImage({ src, alt: item.alt, title: item.title }).run();
        setModal(null);
      },
    });
  };

  const addLink = () => {
    setLinkModal({
      isOpen: true,
      onClose: () => setLinkModal(null),
      onSubmit: (data) => {
        const isEmail = validator.isEmail(data.url);
        const href = isEmail ? `mailto:${data.url}` : data.url;
        const target = "_blank";
        const rel = "noopener noreferrer";
        if (href) editor.chain().focus().extendMarkRange("link").setLink({ href, target, rel }).run();
        setLinkModal(null);
      },
    });
  };

  const clear = () => {
    editor.chain().focus().unsetAllMarks().run();
    editor.chain().focus().clearNodes().run();
  };

  const getFormat = () => {
    if (editor?.isActive("paragraph")) return "Paragraph";
    else if (editor.isActive("heading", { level: 1 })) return "Heading 1";
    else if (editor.isActive("heading", { level: 2 })) return "Heading 2";
    else if (editor.isActive("heading", { level: 3 })) return "Heading 3";
    else if (editor.isActive("heading", { level: 4 })) return "Heading 4";
    else if (editor.isActive("heading", { level: 5 })) return "Heading 5";
    else if (editor.isActive("heading", { level: 6 })) return "Heading 6";
    else return "Format";
  };

  const handleMouseEnter = (r, c) => {
    setHoveredRows(r);
    setHoveredCols(c);
  };

  const handleTableSelect = (rows, cols) => {
    editor.chain().focus().insertTable({ rows, cols, withHeaderRow: true }).run();
    setHoveredRows(0);
    setHoveredCols(0);
  };

  const colors = ["#2831de", "#c00c22", "#9be37b", "#1bcbbe", "#81fcd8", "#80cfa1"];
  const bgColors = ["#d7ea86", "#916d79", "#af8328", "#10cc5a", "#3a398a", "#0f16f8"];

  return (
    <>
      <div className="menu-bar">
        {/* FORMATS */}
        <Menu as="div" className="relative inline-flex text-left">
          <Menu.Button as="button" className="text-xs inline-flex justify-between items-center min-w-[100px] rounded-md">
            <span>{getFormat()}</span>
            <ChevronDownIcon className="w-3 h-3" />
          </Menu.Button>
          <Transition
            as="div"
            enter="transition ease-out duration-100"
            enterFrom="transform opacity-0 scale-95"
            enterTo="transform opacity-100 scale-100"
            leave="transition ease-in duration-75"
            leaveFrom="transform opacity-100 scale-100"
            leaveTo="transform opacity-0 scale-95"
            className="absolute left-0 top-full"
          >
            <Menu.Items
              transition
              anchor="bottom end"
              className="menu-items absolute left-0 w-52 z-30 mt-1 origin-bottom-left rounded-md p-1 border shadow-md bg-white text-sm text-black transition duration-100 ease-out [--anchor-gap:var(--spacing-1)] focus:outline-none data-[closed]:scale-95 data-[closed]:opacity-0"
            >
              <Menu.Item as="div" className="block">
                <button type="button" onClick={() => editor.chain().focus().setParagraph().run()} className={editor.isActive("paragraph") ? "is-active" : ""}>
                  Paragraph
                </button>
              </Menu.Item>
              <Menu.Item as="div" className="block">
                <button type="button" onClick={() => editor.chain().focus().toggleHeading({ level: 1 }).run()} className={`item-h1 ${editor.isActive("heading", { level: 1 }) ? "is-active" : ""}`}>
                  Heading 1
                </button>
              </Menu.Item>
              <Menu.Item as="div" className="block">
                <button type="button" onClick={() => editor.chain().focus().toggleHeading({ level: 2 }).run()} className={`item-h2 ${editor.isActive("heading", { level: 2 }) ? "is-active" : ""}`}>
                  Heading 2
                </button>
              </Menu.Item>
              <Menu.Item as="div" className="block">
                <button type="button" onClick={() => editor.chain().focus().toggleHeading({ level: 3 }).run()} className={`item-h3 ${editor.isActive("heading", { level: 3 }) ? "is-active" : ""}`}>
                  Heading 3
                </button>
              </Menu.Item>
              <Menu.Item as="div" className="block">
                <button type="button" onClick={() => editor.chain().focus().toggleHeading({ level: 4 }).run()} className={`item-h4 ${editor.isActive("heading", { level: 4 }) ? "is-active" : ""}`}>
                  Heading 4
                </button>
              </Menu.Item>
              <Menu.Item as="div" className="block">
                <button type="button" onClick={() => editor.chain().focus().toggleHeading({ level: 5 }).run()} className={`item-h5 ${editor.isActive("heading", { level: 5 }) ? "is-active" : ""}`}>
                  Heading 5
                </button>
              </Menu.Item>
              <Menu.Item as="div" className="block">
                <button type="button" onClick={() => editor.chain().focus().toggleHeading({ level: 6 }).run()} className={`item-h6 ${editor.isActive("heading", { level: 6 }) ? "is-active" : ""}`}>
                  Heading 6
                </button>
              </Menu.Item>
            </Menu.Items>
          </Transition>
        </Menu>

        {/* STYLE */}
        <div className="flex">
          <button onClick={() => editor.chain().focus().toggleBold().run()} disabled={!editor.can().chain().focus().toggleBold().run()} className={editor.isActive("bold") ? "is-active" : ""}>
            <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" className="w-4 h-4">
              <path
                strokeLinejoin="round"
                d="M6.75 3.744h-.753v8.25h7.125a4.125 4.125 0 0 0 0-8.25H6.75Zm0 0v.38m0 16.122h6.747a4.5 4.5 0 0 0 0-9.001h-7.5v9h.753Zm0 0v-.37m0-15.751h6a3.75 3.75 0 1 1 0 7.5h-6m0-7.5v7.5m0 0v8.25m0-8.25h6.375a4.125 4.125 0 0 1 0 8.25H6.75m.747-15.38h4.875a3.375 3.375 0 0 1 0 6.75H7.497v-6.75Zm0 7.5h5.25a3.75 3.75 0 0 1 0 7.5h-5.25v-7.5Z"
              />
            </svg>
          </button>
          <button onClick={() => editor.chain().focus().toggleItalic().run()} disabled={!editor.can().chain().focus().toggleItalic().run()} className={editor.isActive("italic") ? "is-active" : ""}>
            <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" className="w-4 h-4">
              <path strokeLinecap="round" strokeLinejoin="round" d="M5.248 20.246H9.05m0 0h3.696m-3.696 0 5.893-16.502m0 0h-3.697m3.697 0h3.803" />
            </svg>
          </button>
          <button onClick={() => editor.chain().focus().toggleStrike().run()} disabled={!editor.can().chain().focus().toggleStrike().run()} className={editor.isActive("strike") ? "is-active" : ""}>
            <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" className="w-4 h-4">
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                d="M12 12a8.912 8.912 0 0 1-.318-.079c-1.585-.424-2.904-1.247-3.76-2.236-.873-1.009-1.265-2.19-.968-3.301.59-2.2 3.663-3.29 6.863-2.432A8.186 8.186 0 0 1 16.5 5.21M6.42 17.81c.857.99 2.176 1.812 3.761 2.237 3.2.858 6.274-.23 6.863-2.431.233-.868.044-1.779-.465-2.617M3.75 12h16.5"
              />
            </svg>
          </button>
          <button onClick={() => editor.chain().focus().toggleUnderline().run()} disabled={!editor.can().chain().focus().toggleUnderline().run()}>
            <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" className="w-4 h-4">
              <path strokeLinecap="round" strokeLinejoin="round" d="M17.995 3.744v7.5a6 6 0 1 1-12 0v-7.5m-2.25 16.502h16.5" />
            </svg>
          </button>
        </div>

        {/* ALIGNMENTS */}
        <div className="flex">
          <button onClick={() => editor.chain().focus().setTextAlign("left").run()} className={editor.isActive({ textAlign: "left" }) ? "is-active" : ""}>
            <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" className="w-4 h-4">
              <path strokeLinecap="round" strokeLinejoin="round" d="M3.75 6.75h16.5M3.75 12h16.5m-16.5 5.25H12" />
            </svg>
          </button>
          <button onClick={() => editor.chain().focus().setTextAlign("center").run()} className={editor.isActive({ textAlign: "center" }) ? "is-active" : ""}>
            <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" className="w-4 h-4">
              <path strokeLinecap="round" strokeLinejoin="round" d="M3.75 6.75h16.5M3.75 12h16.5M7 17.25h8.50" />
            </svg>
          </button>
          <button onClick={() => editor.chain().focus().setTextAlign("right").run()} className={editor.isActive({ textAlign: "right" }) ? "is-active" : ""}>
            <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" className="w-4 h-4">
              <path strokeLinecap="round" strokeLinejoin="round" d="M3.75 6.75h16.5M3.75 12h16.5M12 17.25h8.25" />
            </svg>
          </button>
          <button onClick={() => editor.chain().focus().setTextAlign("justify").run()} className={editor.isActive({ textAlign: "justify" }) ? "is-active" : ""}>
            <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" className="w-4 h-4">
              <path strokeLinecap="round" strokeLinejoin="round" d="M3.75 6.75h16.5M3.75 12h16.5m-16.5 5.25h16.5" />
            </svg>
          </button>
        </div>

        {/* COLORS */}
        <div className="flex">
          {/* TEXT COLOR */}
          <Menu as="div" className="relative inline-flex text-left">
            <Menu.Button as="button">
              <svg viewBox="0 0 48 48" xmlns="http://www.w3.org/2000/svg" className="w-4 h-4">
                <path d="M17.2,31H30.8l2.6,6h4.4L26.5,11h-5L10.2,37h4.4ZM24,14.9,29.1,27H18.9Z" />
              </svg>
            </Menu.Button>
            <Transition
              as="div"
              enter="transition ease-out duration-100"
              enterFrom="transform opacity-0 scale-95"
              enterTo="transform opacity-100 scale-100"
              leave="transition ease-in duration-75"
              leaveFrom="transform opacity-100 scale-100"
              leaveTo="transform opacity-0 scale-95"
              className="absolute left-0 top-full"
            >
              <Menu.Items
                transition
                anchor="bottom end"
                className="absolute mt-1 left-0 w-24 z-30 origin-bottom-left rounded-md p-1 border shadow-md bg-white text-sm text-black transition duration-100 ease-out [--anchor-gap:var(--spacing-1)] focus:outline-none data-[closed]:scale-95 data-[closed]:opacity-0"
              >
                <Menu.Item as="div" className="block">
                  <div className="table-grid">
                    <div className="grid grid-cols-3">
                      {colors.map((color, index) => (
                        <button
                          key={index}
                          type="button"
                          onClick={() => editor.chain().focus().setColor(color).run()}
                          style={{ backgroundColor: color }}
                          className={`col-span-1 border aspect-square ${editor.isActive("textStyle", { color }) ? "is-active" : ""}`}
                        />
                      ))}
                    </div>
                  </div>
                </Menu.Item>
              </Menu.Items>
            </Transition>
          </Menu>
          {/* BACKGROUND COLOR */}
          <Menu as="div" className="relative inline-flex text-left">
            <Menu.Button as="button">
              <svg viewBox="0 0 48 48" xmlns="http://www.w3.org/2000/svg" className="w-4 h-4">
                <path d="M43,3H5A2,2,0,0,0,3,5V43a2,2,0,0,0,2,2H43a2,2,0,0,0,2-2V5A2,2,0,0,0,43,3ZM33.4,37l-2.6-6H17.2l-2.6,6H10.2L21.5,11h5L37.8,37ZM18.9,27H29.1L24,14.9Z" />
              </svg>
            </Menu.Button>
            <Transition
              as="div"
              enter="transition ease-out duration-100"
              enterFrom="transform opacity-0 scale-95"
              enterTo="transform opacity-100 scale-100"
              leave="transition ease-in duration-75"
              leaveFrom="transform opacity-100 scale-100"
              leaveTo="transform opacity-0 scale-95"
              className="absolute left-0 top-full"
            >
              <Menu.Items
                transition
                anchor="bottom end"
                className="absolute mt-1 left-0 w-24 z-30 origin-bottom-left rounded-md p-1 border shadow-md bg-white text-sm text-black transition duration-100 ease-out [--anchor-gap:var(--spacing-1)] focus:outline-none data-[closed]:scale-95 data-[closed]:opacity-0"
              >
                <Menu.Item as="div" className="block">
                  <div className="table-grid">
                    <div className="grid grid-cols-3">
                      {bgColors.map((color, index) => (
                        <button
                          key={index}
                          type="button"
                          onClick={() => editor.chain().focus().toggleHighlight({ color }).run()}
                          style={{ backgroundColor: color }}
                          className="col-span-1 border aspect-square"
                        />
                      ))}
                    </div>
                  </div>
                </Menu.Item>
              </Menu.Items>
            </Transition>
          </Menu>
        </div>

        {/* LISTS */}
        <div className="flex">
          <button onClick={() => editor.chain().focus().toggleBulletList().run()} className={editor.isActive("bulletList") ? "is-active" : ""}>
            <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" className="w-4 h-4">
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                d="M8.25 6.75h12M8.25 12h12m-12 5.25h12M3.75 6.75h.007v.008H3.75V6.75Zm.375 0a.375.375 0 1 1-.75 0 .375.375 0 0 1 .75 0ZM3.75 12h.007v.008H3.75V12Zm.375 0a.375.375 0 1 1-.75 0 .375.375 0 0 1 .75 0Zm-.375 5.25h.007v.008H3.75v-.008Zm.375 0a.375.375 0 1 1-.75 0 .375.375 0 0 1 .75 0Z"
              />
            </svg>
          </button>
          <button onClick={() => editor.chain().focus().toggleOrderedList().run()} className={editor.isActive("orderedList") ? "is-active" : ""}>
            <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" className="w-4 h-4">
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                d="M8.242 5.992h12m-12 6.003H20.24m-12 5.999h12M4.117 7.495v-3.75H2.99m1.125 3.75H2.99m1.125 0H5.24m-1.92 2.577a1.125 1.125 0 1 1 1.591 1.59l-1.83 1.83h2.16M2.99 15.745h1.125a1.125 1.125 0 0 1 0 2.25H3.74m0-.002h.375a1.125 1.125 0 0 1 0 2.25H2.99"
              />
            </svg>
          </button>
        </div>

        {/* CUSTOM */}
        <div className="flex">
          <button onClick={() => editor.chain().focus().toggleCodeBlock().run()} className={editor.isActive("codeBlock") ? "is-active" : ""}>
            <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" className="w-4 h-4">
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                d="m6.75 7.5 3 2.25-3 2.25m4.5 0h3m-9 8.25h13.5A2.25 2.25 0 0 0 21 18V6a2.25 2.25 0 0 0-2.25-2.25H5.25A2.25 2.25 0 0 0 3 6v12a2.25 2.25 0 0 0 2.25 2.25Z"
              />
            </svg>
          </button>
          <button onClick={() => editor.chain().focus().toggleBlockquote().run()} className={editor.isActive("blockquote") ? "is-active" : ""}>
            <svg viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg" fill="black" className="w-4 h-4">
              <path d="M8.19 16.78H9.68C10.77 16.78 11.62 15.93 11.62 14.84V13.35C11.62 12.26 10.77 11.41 9.68 11.41H7.77C7.85 9.59997 8.27 9.33 9.48 8.62C9.84 8.41 9.95 7.95003 9.74 7.59003C9.6 7.35003 9.35 7.21997 9.09 7.21997C8.96 7.21997 8.83 7.25001 8.71 7.32001C6.92 8.38001 6.25 9.07002 6.25 12.15V14.82C6.25 15.91 7.12 16.78 8.19 16.78Z" />
              <path d="M14.3209 16.78H15.8109C16.9009 16.78 17.7509 15.93 17.7509 14.84V13.35C17.7509 12.26 16.9009 11.41 15.8109 11.41H13.9009C13.9809 9.59997 14.4009 9.33 15.6109 8.62C15.9709 8.41 16.0809 7.95003 15.8709 7.59003C15.7309 7.35003 15.4809 7.21997 15.2209 7.21997C15.0909 7.21997 14.9609 7.25001 14.8409 7.32001C13.0509 8.38001 12.3809 9.07002 12.3809 12.15V14.82C12.3909 15.91 13.2609 16.78 14.3209 16.78Z" />
            </svg>
          </button>
          <button onClick={() => editor.chain().focus().setHorizontalRule().run()}>
            <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" className="w-4 h-4">
              <path strokeLinecap="round" strokeLinejoin="round" d="M5 12h14" />
            </svg>
          </button>
        </div>

        {/* TABLE */}
        <div className="flex">
          <Menu as="div" className="relative inline-flex text-left">
            <Menu.Button as="button">
              <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" className="w-4 h-4">
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  d="M3.375 19.5h17.25m-17.25 0a1.125 1.125 0 0 1-1.125-1.125M3.375 19.5h7.5c.621 0 1.125-.504 1.125-1.125m-9.75 0V5.625m0 12.75v-1.5c0-.621.504-1.125 1.125-1.125m18.375 2.625V5.625m0 12.75c0 .621-.504 1.125-1.125 1.125m1.125-1.125v-1.5c0-.621-.504-1.125-1.125-1.125m0 3.75h-7.5A1.125 1.125 0 0 1 12 18.375m9.75-12.75c0-.621-.504-1.125-1.125-1.125H3.375c-.621 0-1.125.504-1.125 1.125m19.5 0v1.5c0 .621-.504 1.125-1.125 1.125M2.25 5.625v1.5c0 .621.504 1.125 1.125 1.125m0 0h17.25m-17.25 0h7.5c.621 0 1.125.504 1.125 1.125M3.375 8.25c-.621 0-1.125.504-1.125 1.125v1.5c0 .621.504 1.125 1.125 1.125m17.25-3.75h-7.5c-.621 0-1.125.504-1.125 1.125m8.625-1.125c.621 0 1.125.504 1.125 1.125v1.5c0 .621-.504 1.125-1.125 1.125m-17.25 0h7.5m-7.5 0c-.621 0-1.125.504-1.125 1.125v1.5c0 .621.504 1.125 1.125 1.125M12 10.875v-1.5m0 1.5c0 .621-.504 1.125-1.125 1.125M12 10.875c0 .621.504 1.125 1.125 1.125m-2.25 0c.621 0 1.125.504 1.125 1.125M13.125 12h7.5m-7.5 0c-.621 0-1.125.504-1.125 1.125M20.625 12c.621 0 1.125.504 1.125 1.125v1.5c0 .621-.504 1.125-1.125 1.125m-17.25 0h7.5M12 14.625v-1.5m0 1.5c0 .621-.504 1.125-1.125 1.125M12 14.625c0 .621.504 1.125 1.125 1.125m-2.25 0c.621 0 1.125.504 1.125 1.125m0 1.5v-1.5m0 0c0-.621.504-1.125 1.125-1.125m0 0h7.5"
                />
              </svg>
            </Menu.Button>
            <Transition
              as="div"
              enter="transition ease-out duration-100"
              enterFrom="transform opacity-0 scale-95"
              enterTo="transform opacity-100 scale-100"
              leave="transition ease-in duration-75"
              leaveFrom="transform opacity-100 scale-100"
              leaveTo="transform opacity-0 scale-95"
              className="absolute left-0 top-full"
            >
              <Menu.Items
                transition
                anchor="bottom end"
                className="absolute left-0 w-24 z-30 origin-bottom-left rounded-md p-1 border shadow-md bg-white text-sm text-black transition duration-100 ease-out [--anchor-gap:var(--spacing-1)] focus:outline-none data-[closed]:scale-95 data-[closed]:opacity-0"
              >
                <Menu.Item as="div" className="block">
                  <div className="table-grid">
                    {[...Array(5)].map((_, r) => (
                      <div key={r} className="grid grid-cols-5">
                        {[...Array(5)].map((_, c) => (
                          <div
                            key={c}
                            onMouseEnter={() => handleMouseEnter(r + 1, c + 1)}
                            onClick={() => handleTableSelect(r + 1, c + 1)}
                            className={`col-span-1 border aspect-square ${r < hoveredRows && c < hoveredCols ? "bg-primary-500" : ""}`}
                          />
                        ))}
                      </div>
                    ))}
                  </div>
                  <p className="text-[8px] text-center">
                    {hoveredRows} x {hoveredCols}
                  </p>
                </Menu.Item>
                <Menu.Item as="div" className="block">
                  <div aria-disabled={!editor.can().deleteColumn()} className="mt-2 text-xs aria-disabled:hidden">
                    <p>Column</p>
                    <div className="flex gap-1">
                      <button type="button" onClick={() => editor.chain().focus().mergeOrSplit().run()} disabled={!editor.can().mergeOrSplit()} className="text-xs disabled:hidden">
                        Merge or split
                      </button>
                      <button type="button" onClick={() => editor.chain().focus().addColumnBefore().run()} disabled={!editor.can().addColumnBefore()} className="text-xs disabled:hidden">
                        Add left
                      </button>
                      <button type="button" onClick={() => editor.chain().focus().addColumnAfter().run()} disabled={!editor.can().addColumnAfter()} className="text-xs disabled:hidden">
                        Add right
                      </button>
                      <button type="button" onClick={() => editor.chain().focus().deleteColumn().run()} disabled={!editor.can().deleteColumn()} className="text-xs disabled:hidden">
                        Delete
                      </button>
                    </div>
                  </div>
                </Menu.Item>
                <Menu.Item as="div" className="block">
                  <div aria-disabled={!editor.can().deleteRow()} className="mt-2 text-xs aria-disabled:hidden">
                    <p>Row</p>
                    <div className="flex gap-1">
                      <button type="button" onClick={() => editor.chain().focus().addRowBefore().run()} disabled={!editor.can().addRowBefore()} className="text-xs disabled:hidden">
                        Add above
                      </button>
                      <button type="button" onClick={() => editor.chain().focus().addRowAfter().run()} disabled={!editor.can().addRowAfter()} className="text-xs disabled:hidden">
                        Add below
                      </button>
                      <button type="button" onClick={() => editor.chain().focus().deleteRow().run()} disabled={!editor.can().deleteRow()} className="text-xs disabled:hidden">
                        Delete
                      </button>
                    </div>
                  </div>
                </Menu.Item>
                <Menu.Item as="div" className="block">
                  <div className="mt-4">
                    <button type="button" onClick={() => editor.chain().focus().deleteTable().run()} disabled={!editor.can().deleteTable()} className="text-xs disabled:hidden">
                      Delete table
                    </button>
                  </div>
                </Menu.Item>
              </Menu.Items>
            </Transition>
          </Menu>
          <button onClick={addLink} disabled={!editor.can().setLink()}>
            <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" className="w-4 h-4">
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                d="M13.19 8.688a4.5 4.5 0 0 1 1.242 7.244l-4.5 4.5a4.5 4.5 0 0 1-6.364-6.364l1.757-1.757m13.35-.622 1.757-1.757a4.5 4.5 0 0 0-6.364-6.364l-4.5 4.5a4.5 4.5 0 0 0 1.242 7.244"
              />
            </svg>
          </button>
          <button onClick={addImage}>
            <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" className="w-4 h-4">
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                d="m2.25 15.75 5.159-5.159a2.25 2.25 0 0 1 3.182 0l5.159 5.159m-1.5-1.5 1.409-1.409a2.25 2.25 0 0 1 3.182 0l2.909 2.909m-18 3.75h16.5a1.5 1.5 0 0 0 1.5-1.5V6a1.5 1.5 0 0 0-1.5-1.5H3.75A1.5 1.5 0 0 0 2.25 6v12a1.5 1.5 0 0 0 1.5 1.5Zm10.5-11.25h.008v.008h-.008V8.25Zm.375 0a.375.375 0 1 1-.75 0 .375.375 0 0 1 .75 0Z"
              />
            </svg>
          </button>
        </div>

        {/* HISTORY */}
        <div className="flex">
          <button onClick={() => editor.chain().focus().undo().run()} disabled={!editor.can().chain().focus().undo().run()}>
            <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" className="w-4 h-4">
              <path strokeLinecap="round" strokeLinejoin="round" d="M9 15 3 9m0 0 6-6M3 9h12a6 6 0 0 1 0 12h-3" />
            </svg>
          </button>
          <button onClick={() => editor.chain().focus().redo().run()} disabled={!editor.can().chain().focus().redo().run()}>
            <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" className="w-4 h-4">
              <path strokeLinecap="round" strokeLinejoin="round" d="m15 15 6-6m0 0-6-6m6 6H9a6 6 0 0 0 0 12h3" />
            </svg>
          </button>
        </div>

        {/* CLEAN */}
        <div className="flex">
          <button onClick={clear}>
            <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" className="w-4 h-4">
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                d="M9.53 16.122a3 3 0 0 0-5.78 1.128 2.25 2.25 0 0 1-2.4 2.245 4.5 4.5 0 0 0 8.4-2.245c0-.399-.078-.78-.22-1.128Zm0 0a15.998 15.998 0 0 0 3.388-1.62m-5.043-.025a15.994 15.994 0 0 1 1.622-3.395m3.42 3.42a15.995 15.995 0 0 0 4.764-4.648l3.876-5.814a1.151 1.151 0 0 0-1.597-1.597L14.146 6.32a15.996 15.996 0 0 0-4.649 4.763m3.42 3.42a6.776 6.776 0 0 0-3.42-3.42"
              />
            </svg>
          </button>
        </div>

        {/* <button onClick={() => editor.chain().focus().clearNodes().run()}>Clear nodes</button> */}
        {/* <button onClick={() => editor.chain().focus().toggleHighlight().run()} className={editor.isActive("highlight") ? "is-active" : ""}>
            Highlight
          </button> */}
        {/* <button onClick={() => editor.chain().focus().setHardBreak().run()}>Hard break</button> */}
        {/* <button onClick={() => editor.chain().focus().deleteTable().run()} disabled={!editor.can().deleteTable()}>
            Delete table
          </button> */}
        {/* <button onClick={() => editor.chain().focus().mergeCells().run()} disabled={!editor.can().mergeCells()}>
          Merge cells
        </button>
        <button onClick={() => editor.chain().focus().splitCell().run()} disabled={!editor.can().splitCell()}>
          Split cell
        </button> */}
        {/* <button onClick={() => editor.chain().focus().toggleHeaderColumn().run()} disabled={!editor.can().toggleHeaderColumn()}>
          ToggleHeaderColumn
        </button>
        <button onClick={() => editor.chain().focus().toggleHeaderRow().run()} disabled={!editor.can().toggleHeaderRow()}>
          Toggle header row
        </button>
        <button onClick={() => editor.chain().focus().toggleHeaderCell().run()} disabled={!editor.can().toggleHeaderCell()}>
          Toggle header cell
        </button> */}
        {/* <button onClick={() => editor.chain().focus().mergeOrSplit().run()} disabled={!editor.can().mergeOrSplit()}>
            Merge or split
          </button> */}
        {/* <button onClick={() => editor.chain().focus().setCellAttribute("backgroundColor", "#FAF594").run()} disabled={!editor.can().setCellAttribute("backgroundColor", "#FAF594")}>
          Set cell attribute
        </button>
        <button onClick={() => editor.chain().focus().fixTables().run()} disabled={!editor.can().fixTables()}>
          Fix tables
        </button> */}
        {/* <button onClick={() => editor.chain().focus().goToNextCell().run()} disabled={!editor.can().goToNextCell()}>
          Go to next cell
        </button>
        <button onClick={() => editor.chain().focus().goToPreviousCell().run()} disabled={!editor.can().goToPreviousCell()}>
          Go to previous cell
        </button> */}
      </div>

      <ModalMedia {...modal} />
      <ModalLink {...linkModal} />
    </>
  );
}
