import { Content, Footer, Header, Struture } from "app/components/atoms/modal";
import Form from "app/components/organisms/form";
import { cleanStructure } from "app/utils/content";
import { validation } from "app/utils/validators";
import React, { useEffect, useState } from "react";
import { components } from "./constants";
import { i18n } from "app/i18n";
import { getFlag } from "app/utils/languages";

export default function ModalMapCategory(props) {
  const { category, isOpen = false, title, languages, isLoading, onSubmit, onClose = () => {} } = props;

  const [value, setValue] = useState(null);
  const [structure, setStructure] = useState(null);

  useEffect(() => {
    if (isOpen) {
      buildStructure();
    } else {
      setValue(null);
      setStructure(cleanStructure(components));
    }
  }, [isOpen, category]);

  const buildStructure = () => {
    let translations = {};
    const tempStructure = cleanStructure(components);

    // REMOVE INPUT
    const keyInput = category ? "title" : "translations";

    const index = tempStructure.findIndex((item) => item.key === keyInput);
    if (index !== -1) tempStructure.splice(index, 1);

    if (languages?.length) {
      const index = tempStructure.findIndex((item) => item.key === "translations");
      if (index === -1) return null;

      const input = tempStructure[index].children[0];
      tempStructure[index].children = [];

      for (let i = 0; i < languages.length; i++) {
        const title = category?.translations?.find((e) => e.language.slug === languages[i].language.slug);
        translations[languages[i].language.slug] = title?.value || "";

        tempStructure[index].children.push({
          ...input,
          key: languages[i].language.slug,
          title: `${getFlag(languages[i].language.slug)} ${languages[i].language.name}`,
        });
      }
    }
    setStructure([...tempStructure]);

    const types = {
      STORY: { value: "STORY", label: "Story" },
      EXPERIENCE: { value: "EXPERIENCE", label: "Experience" },
      OFFER: { value: "OFFER", label: "Offer" },
      PARTNER: { value: "PARTNER", label: "Partner" },
    };

    if (category) setValue({ icon: category.icon, types: category.types?.filter((e) => types[e])?.map((e) => types[e]), translations });
  };

  const onClickSubmit = () => {
    validation(structure, value, (structure, validation) => {
      setStructure([...structure]);
      if (validation.isValid) onSubmit(value);
    });
  };

  return (
    <Struture isOpen={isOpen} onClose={onClose}>
      <Header title={title} onClose={onClose} />
      <Content>
        <Form value={value} onChange={setValue} structure={structure} disabled={isLoading} />
      </Content>
      <Footer loading={isLoading} cancel={{ text: i18n("button.close"), onClick: onClose }} submit={{ text: i18n("button.save"), disabled: isLoading, onClick: onClickSubmit }} />
    </Struture>
  );
}
