import { Content, Footer, Header, Struture } from "app/components/atoms/modal";
import { i18n } from "app/i18n";
import { getSource } from "app/utils/content";
import React from "react";
import { useSelector } from "react-redux";

export default function ModalRouteDetails(props) {
  const { isOpen = false, info, onUpdate, onConvert, onClose = () => {} } = props;

  // PARAMS
  const { language } = useSelector((store) => store.project);

  return (
    <Struture isOpen={isOpen} onClose={onClose}>
      <Header title={i18n("label.route_detail")} onClose={onClose} />
      <Content>
        {info && (
          <>
            <div className="mb-8">
              <h1 className="text-xl font-bold">{info.title}</h1>
              {info.description && <div>{info.description}</div>}
              {info.author && info.showAuthor && (
                <div className="text-sm">
                  <strong>Autor:</strong> {info.authorName || info.author.name}
                </div>
              )}
              {info.status && (
                <div className="text-sm">
                  <strong>Estado:</strong> {info.status === "DRAFT" ? "Pessoal" : "Público"}
                </div>
              )}
              {onUpdate && <button onClick={onUpdate}>EDIT</button>}
            </div>
            <div>
              {info?.elements?.length && (
                <div className="space-y-2">
                  {info.elements.map(({ element, index }) => {
                    const translation = element.translations.find((e) => e.language.slug === language.slug);

                    return (
                      <div key={index} className="border flex items-start gap-4 rounded-lg p-2">
                        <div className="">
                          <div className="relative h-20 aspect-square rounded-lg overflow-hidden bg-slate-100">
                            {element?.image?.href && <img src={getSource(element.image.href)} alt="" className="absolute inset-0 w-full h-full object-cover" />}
                          </div>
                        </div>
                        <div className="">
                          <div className="flex items-center mb-1 gap-3">
                            <div className="inline-block text-[10px] text-white bg-blue-500 py-0.5 px-2 rounded-md">{element.type}</div>
                            <div className="flex items-center gap-1">
                              {element?.place?.city?.name && <span className="text-[10px]">{element.place.city.name},</span>}
                              {element?.place?.name && <span className="text-[10px]">{element.place.name}</span>}
                            </div>
                          </div>
                          <div>{translation?.title}</div>
                          <div>{element?.duration && <span className="text-[10px] py-1 px-3 border rounded-full">{element.duration} minutos</span>}</div>
                        </div>
                      </div>
                    );
                  })}
                </div>
              )}
            </div>
          </>
        )}
      </Content>
      <Footer cancel={{ text: i18n("button.close"), onClick: onClose }} submit={{ text: i18n("button.convert_to_suggestion"), onClick: onConvert }} />
    </Struture>
  );
}
