import { Content, Footer, Header, Struture } from "app/components/atoms/modal";
import Form from "app/components/organisms/form";
import { cleanStructure } from "app/utils/content";
import { validation } from "app/utils/validators";
import React, { useEffect, useState } from "react";
import { components } from "./constants";
import { i18n } from "app/i18n";

export default function ModalElement(props) {
  const { isOpen = false, title, type, duplicate, languages, element, language, isLoading, onSubmit, onClose = () => {} } = props;

  const [value, setValue] = useState(null);
  const [structure, setStructure] = useState(null);

  useEffect(() => {
    if (isOpen) {
      buildStructure();
    } else {
      setValue(null);
      setStructure(cleanStructure(components));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isOpen]);

  const buildStructure = () => {
    const tempStructure = cleanStructure(components);

    if (type) {
      const index = tempStructure.findIndex((item) => item.key === "slug");
      if (index !== -1) {
        tempStructure[index].rules[1].args.type = type;
        tempStructure[index].rules[1].args.language = language;
        if (element) tempStructure[index].rules[1].args.id = element.id;
      }
    }

    if (!duplicate) {
      const index = tempStructure.findIndex((item) => item.key === "content");
      if (index !== -1) tempStructure.splice(index, 1);
      const indexTranslate = tempStructure.findIndex((item) => item.key === "auto");
      if (indexTranslate !== -1) tempStructure.splice(indexTranslate, 1);
    } else {
      if (languages?.length) {
        const index = tempStructure.findIndex((item) => item.key === "content");
        if (index !== -1) tempStructure[index].options = languages;
      }
    }

    setStructure([...tempStructure]);
  };

  const onClickSubmit = () => {
    validation(structure, value, (structure, validation) => {
      setStructure([...structure]);
      if (validation.isValid) onSubmit(value);
    });
  };

  return (
    <Struture isOpen={isOpen} onClose={onClose}>
      <Header title={title} onClose={onClose} />
      <Content>
        <Form value={value} onChange={setValue} structure={structure} disabled={isLoading} />
      </Content>
      <Footer loading={isLoading} cancel={{ text: i18n("button.close"), onClick: onClose }} submit={{ text: i18n("button.save"), disabled: isLoading, onClick: onClickSubmit }} />
    </Struture>
  );
}
