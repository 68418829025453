import { PencilIcon, PlusIcon } from "@heroicons/react/24/outline";
import Icon from "app/components/atoms/icons/icon";
import OnOff from "app/components/atoms/onOff";
import Dropdown from "app/components/molecules/dropdown";
import ModalConfirm from "app/components/molecules/modals/modalConfirm";
import ModalElement from "app/components/molecules/modals/modalElement";
import Pagination from "app/components/molecules/pagination";
import Permission from "app/components/molecules/permission";
import { i18n } from "app/i18n";
import { setPage, useCreateExperienceMutation, useDeleteExperienceMutation, useGetExperiencesCountQuery, useGetExperiencesQuery, useTranslateElementMutation } from "app/stores/private";
import { getFlag } from "app/utils/languages";
import { showServerError, showServerSuccess } from "app/utils/server";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link, useLocation, useNavigate, useParams } from "react-router-dom";

export default function Experience() {
  // DISPATCH
  const dispatch = useDispatch();
  const navigate = useNavigate();

  // PARAMS
  const { project } = useParams();
  const { pathname } = useLocation();
  const { language, languages } = useSelector((store) => store.project);
  const { take, skip, where, orderBy, page } = useSelector((store) => store.private);

  // STATES
  const [modal, setModal] = useState(null);
  const [modalConfirm, setModalConfirm] = useState(null);

  // QUERY
  let { data: total, isLoading: isGettingTotal } = useGetExperiencesCountQuery({ where });
  const { data, refetch, isLoading: isGetting } = useGetExperiencesQuery({ take, skip, where, orderBy });

  // MUTATION
  const [createExperience, { isLoading: isCreating }] = useCreateExperienceMutation();
  const [deleteExperience, { isLoading: isDeleting }] = useDeleteExperienceMutation();
  const [translateElement, { isLoading: isTranslating }] = useTranslateElementMutation();

  // VARS
  const type = "EXPERIENCE";
  const classType = pathname?.split("/")[4];
  const isLoading = isGetting || isGettingTotal || isCreating || isDeleting || isTranslating;

  useEffect(() => {
    return () => dispatch(setPage(0));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  // FUNCTIONS
  const onCreateElement = () => {
    setModal({
      type,
      element: null,
      isOpen: true,
      title: "Create Experience",
      project: project,
      language: language.slug,
      onClose: () => setModal(null),
      onSubmit: (data) => {
        createExperience({ ...data, language: language.slug, type }).then((res) => {
          if (res?.data) showServerSuccess(i18n("toast.success_create"));
          else showServerError(res);
          refetch();
          total++;
        });
        setModal(null);
      },
    });
  };
  const onDeleteElement = (item) => {
    setModalConfirm({
      isOpen: true,
      type: "DELETE",
      title: "Delete Experience",
      text: "Do you really want to delete this experience?",
      buttonText: "Delete",
      onClose: () => setModalConfirm(null),
      onConfirm: () => {
        deleteExperience({ id: item.id }).then((res) => {
          if (res?.data) showServerSuccess(i18n("toast.success_create"));
          else showServerError(res);
          refetch();
          total--;
        });
        setModalConfirm(null);
      },
    });
  };
  const onCreateTranslation = (item, language) => {
    setModal({
      type,
      isOpen: true,
      duplicate: true,
      language: language.slug,
      title: `Translate ${type.toLowerCase()}`,
      languages: item.translations.map((e) => ({ value: e.id, label: `${getFlag(e.language.slug)} ${e.language.slug.toUpperCase()}` })),
      onClose: () => setModal(null),
      onSubmit: (data) => {
        translateElement({ element: item.id, language: language.slug, title: data.title, slug: data.slug, content: data.content, auto: data.auto }).then((res) => {
          if (res?.data) {
            showServerSuccess(i18n("toast.success_update"));
            navigate(`/project/${project}/dashboard/${classType}/${item.id}/${language.slug}`);
          } else {
            showServerError(res);
          }
          setModal(null);
        });
      },
    });
  };

  return (
    <div className="max-w-7xl mx-auto pt-20 px-6">
      {/* BREADCRUMBS */}
      <div className="mb-3">
        <Link to={`/project/${project}/dashboard`} className="inline-flex items-center gap-2 text-sm text-gray-400 hover:text-primary-500 transition-all duration-200 ease-linear">
          <Icon name="chevronLeft" height="h-4" width="w-4" />
          <span>{i18n("label.back")}</span>
        </Link>
      </div>

      {/* HEADER */}
      <div className="flex items-center justify-between  mb-10">
        <h1 className="text-2xl font-bold text-slate-900">Experiências</h1>
        {isLoading && "loading..."}
        <button type="button" onClick={onCreateElement} className="btn-primary-outline">
          <Icon name="plus" height="h-4" width="w-4" />
          <span>{i18n("button.add_new")}</span>
        </button>
      </div>

      <div className="overflow-x-auto overflow-y-visible relative h-full pb-24">
        <table className="listTable w-full text-sm text-left text-gray-500 dark:text-gray-400">
          <thead className="relative text-xs text-gray-700 before:bg-gray-200 before:absolute before:inset-0 before:bottom-[10px] before:rounded-lg after:leading-[10px] after:-indent-96 after:block after:content-['']">
            <tr className="relative">
              <th scope="col" className="p-6">
                <span>{i18n("label.title")}</span>
              </th>
              <th className="px-6">
                {!!languages?.length && (
                  <>
                    {languages?.map((lang, key) => (
                      <span key={key} className="uppercase text-xl h-4 w-6 mx-1 inline-flex items-center justify-center">
                        {getFlag(lang.language.slug)}
                      </span>
                    ))}
                  </>
                )}
              </th>
              <th></th>
            </tr>
          </thead>
          {!!data?.length ? (
            <tbody className="relative">
              {data.map((item, key) => {
                const translation = language ? item.translations.find((e) => e.language.slug === language.slug) : null;
                return (
                  <tr key={key}>
                    <td>
                      <Link to={`${item.id}/${language?.slug}`} className="flex items-center gap-2">
                        <OnOff active={translation?.published} />
                        <div>
                          <div className="text-sm text-slate-700 leading-none whitespace-nowrap">{translation?.title || "-"}</div>
                          <span className="text-[10px] leading-none text-slate-300">{translation?.slug || "-"}</span>
                        </div>
                      </Link>
                    </td>
                    <td>
                      {!!languages?.length && (
                        <>
                          {languages?.map((lang, key) => {
                            const exists = item.translations?.length ? item.translations.findIndex((item) => item.language.slug === lang.language.slug) : undefined;
                            return (
                              <span key={key} className="h-4 w-6 mx-1 inline-flex items-center justify-center transition-all ease-in-out duration-500 text-gray-500 hover:text-gray-900">
                                {exists !== -1 ? (
                                  <Link to={`${item.id}/${lang.language.slug}`}>
                                    <PencilIcon className="w-5" />
                                  </Link>
                                ) : (
                                  <Permission project dev min="MANAGER">
                                    <button type="button" onClick={() => onCreateTranslation(item, lang.language)}>
                                      <PlusIcon className="w-5" />
                                    </button>
                                  </Permission>
                                )}
                              </span>
                            );
                          })}
                        </>
                      )}
                    </td>
                    <td>
                      <Dropdown icon="ellipsis" options={[{ title: i18n("button.delete"), onClick: () => onDeleteElement(item), icon: "delete", delete: true }]} />
                    </td>
                  </tr>
                );
              })}
            </tbody>
          ) : (
            <tbody className="relative">
              <tr>
                <td colSpan={3} className="!text-center">
                  {i18n("label.nothing_found")}
                </td>
              </tr>
            </tbody>
          )}
        </table>
      </div>

      <div className="flex justify-center mb-20">
        <Pagination total={total} perPage={take} current={page} onChange={(p) => dispatch(setPage(p))} />
      </div>

      <ModalElement {...modal} isLoading={isLoading} />
      <ModalConfirm {...modalConfirm} isLoading={isLoading} />
    </div>
  );
}
