import { gql } from "@apollo/client";
import { createApi } from "@reduxjs/toolkit/query/react";
import { graphqlRequestBaseQuery } from "@rtk-query/graphql-request-base-query";
import { createSlice } from "@reduxjs/toolkit";
import { serverAuth } from "app/utils/server";
import { addItemToBuilderHistory, updateSectionData } from "app/utils/visual";

const initialState = {
  take: 10,

  section: null,

  active: 0, // active element in history
  history: null,

  customInputs: null,
};

export const sectionsSlice = createSlice({
  name: "sections",
  initialState,
  reducers: {
    setSection: (state, { payload }) => {
      state.section = payload;
    },
    setHistory: (state, { payload }) => {
      state.history = payload;
    },
    setActiveHistory: (state, { payload }) => {
      state.active = payload;
    },
    setCustomInputs: (state, { payload }) => {
      state.customInputs = payload;
    },
  },
});

export const buildersApi = createApi({
  reducerPath: "buildersApi",
  baseQuery: graphqlRequestBaseQuery(serverAuth),
  endpoints: (builder) => ({
    // GLOBAL
    getCustomInputs: builder.query({
      query: () => ({
        document: gql`
          query environment($key: String!) {
            environment(key: $key) {
              value
            }
          }
        `,
        variables: {
          key: "builder",
        },
      }),
      transformResponse: (response) => (response.environment ? response.environment.value : null),
      async onQueryStarted(params, { dispatch, queryFulfilled }) {
        try {
          const { data } = await queryFulfilled;

          // UPDATE LIST OF CUSTOM INPUTS
          dispatch(sectionsSlice.actions.setCustomInputs(data));
        } catch (err) {
          console.error(err);
        }
      },
    }),
    addCustomInput: builder.mutation({
      query: ({ key, value }) => ({
        document: gql`
          mutation createOrUpdateEnvironment($key: String!, $value: Json!) {
            createOrUpdateEnvironment(key: $key, value: $value) {
              value
            }
          }
        `,
        variables: {
          key,
          value,
        },
      }),
      transformResponse: (response) => (response.createOrUpdateEnvironment ? response.createOrUpdateEnvironment.value : null),
      async onQueryStarted(params, { dispatch, queryFulfilled, getState }) {
        try {
          const { data } = await queryFulfilled;

          // UPDATE LIST OF CUSTOM INPUTS
          if (params?.key === "builder") dispatch(sectionsSlice.actions.setCustomInputs([...data]));
        } catch (err) {
          console.error(err);
        }
      },
    }),
    // SECTIONS
    getSections: builder.query({
      query: ({ take, skip, where, orderBy }) => ({
        document: gql`
          query sections($take: Int, $skip: Int, $where: SectionWhere, $orderBy: SectionOrderBy) {
            sections(take: $take, skip: $skip, where: $where, orderBy: $orderBy) {
              id
              key
              title
            }
          }
        `,
        variables: {
          take,
          skip,
          where,
          orderBy,
        },
      }),
      transformResponse: (response) => response.sections,
    }),
    getSection: builder.query({
      query: ({ id }) => ({
        document: gql`
          query section($id: String!) {
            section(id: $id) {
              id
              key
              title
              inUse
              structure
              thumbnail {
                id
                href
              }
              templates {
                template {
                  title
                }
              }
            }
          }
        `,
        variables: {
          id,
        },
      }),
      transformResponse: (response) => response.section,
      async onQueryStarted(params, { dispatch, queryFulfilled, getState }) {
        try {
          const { data } = await queryFulfilled;

          // dispatch(sectionsSlice.actions.setSection({ ...data }));

          // START DATA FROM CURRENT PROJECT
          addItemToBuilderHistory([], undefined, undefined, data?.structure, sectionsSlice, dispatch);
        } catch (err) {
          console.error(err);
        }
      },
    }),
    createSection: builder.mutation({
      query: ({ title, key, thumbnail }) => ({
        document: gql`
          mutation createSection($title: String!, $key: String!, $thumbnail: GenericConnect) {
            createSection(title: $title, key: $key, thumbnail: $thumbnail) {
              id
            }
          }
        `,
        variables: {
          title,
          key,
          thumbnail,
        },
      }),
      transformResponse: (response) => response.createSection,
    }),
    updateSection: builder.mutation({
      query: ({ id, title, key, structure, thumbnail }) => ({
        document: gql`
          mutation updateSection($id: String!, $title: String, $key: String, $structure: Json, $thumbnail: GenericConnect) {
            updateSection(id: $id, title: $title, key: $key, structure: $structure, thumbnail: $thumbnail) {
              id
              key
              title
              structure
              thumbnail {
                id
                href
              }
            }
          }
        `,
        variables: {
          id,
          key,
          title,
          structure,
          thumbnail,
        },
      }),
      transformResponse: (response) => response.updateSection,
      async onQueryStarted(params, { dispatch, queryFulfilled, getState }) {
        try {
          const { data } = await queryFulfilled;
          const { builder } = getState();

          updateSectionData(builder?.section, data, sectionsSlice, dispatch);

          // UPDATE DATA FROM CURRENT PROJECT
          if (!params?.history) addItemToBuilderHistory(builder?.history, builder?.active, !!params?.history, data?.structure, sectionsSlice, dispatch);
        } catch (err) {
          console.error(err);
        }
      },
    }),
    deleteSection: builder.mutation({
      query: ({ id }) => ({
        document: gql`
          mutation deleteSection($id: String!) {
            deleteSection(id: $id) {
              id
            }
          }
        `,
        variables: {
          id,
        },
      }),
      transformResponse: (response) => response.deleteSection,
    }),
    // TEMPLATES
    getTemplates: builder.query({
      query: ({ take, skip, where, orderBy }) => ({
        document: gql`
          query templates($take: Int, $skip: Int, $where: TemplateWhere, $orderBy: TemplateOrderBy) {
            templates(take: $take, skip: $skip, where: $where, orderBy: $orderBy) {
              id
              title
            }
          }
        `,
        variables: {
          take,
          skip,
          where,
          orderBy,
        },
      }),
      transformResponse: (response) => response.templates,
    }),
    getTemplate: builder.query({
      query: ({ id }) => ({
        document: gql`
          query template($id: String!) {
            template(id: $id) {
              id
              title
              inUse
              sections {
                id
                order
                section {
                  id
                  title
                  thumbnail {
                    id
                    href
                  }
                }
              }
            }
          }
        `,
        variables: {
          id,
        },
      }),
      transformResponse: (response) => response.template,
    }),
    createTemplate: builder.mutation({
      query: ({ title }) => ({
        document: gql`
          mutation createTemplate($title: String!) {
            createTemplate(title: $title) {
              id
            }
          }
        `,
        variables: {
          title,
        },
      }),
      transformResponse: (response) => response.createTemplate,
    }),
    updateTemplate: builder.mutation({
      query: ({ id, title, sections }) => ({
        document: gql`
          mutation updateTemplate($id: String!, $title: String, $sections: InputSetSections) {
            updateTemplate(id: $id, title: $title, sections: $sections) {
              id
            }
          }
        `,
        variables: {
          id,
          title,
          sections,
        },
      }),
      transformResponse: (response) => response.updateTemplate,
    }),
    deleteTemplate: builder.mutation({
      query: ({ id }) => ({
        document: gql`
          mutation deleteTemplate($id: String!) {
            deleteTemplate(id: $id) {
              id
            }
          }
        `,
        variables: {
          id,
        },
      }),
      transformResponse: (response) => response.deleteTemplate,
    }),
    // POST TYPES
    getPostTypes: builder.query({
      query: ({ take, skip, where, orderBy }) => ({
        document: gql`
          query postTypes($take: Int, $skip: Int, $where: PostTypeWhere, $orderBy: PostTypeOrderBy) {
            postTypes(take: $take, skip: $skip, where: $where, orderBy: $orderBy) {
              id
              key
              title
              webservice
            }
          }
        `,
        variables: {
          take,
          skip,
          where,
          orderBy,
        },
      }),
      transformResponse: (response) => response.postTypes,
    }),
    getPostType: builder.query({
      query: ({ id }) => ({
        document: gql`
          query postType($id: String!) {
            postType(id: $id) {
              id
              key
              title
              structure
              hierarchy
              singlePage
              webservice
              count(where: { translations: { some: { published: true } } })
              templates {
                id
              }
              projects {
                id
              }
              slugs {
                value
                language {
                  slug
                }
              }
              categories {
                id
                title
                key
                icon
                image
                multi
                hierarchy
              }
            }
          }
        `,
        variables: {
          id,
        },
      }),
      transformResponse: (response) => response.postType,
    }),
    createPostType: builder.mutation({
      query: ({ title, key, slugs, templates, categories, singlePage, hierarchy, webservice, projects }) => ({
        document: gql`
          mutation createPostType(
            $title: String!
            $key: String!
            $templates: [String!]
            $slugs: [PostTypeSlugsInput!]!
            $categories: [PostTypeCategoryInput!]
            $singlePage: Boolean!
            $hierarchy: Boolean!
            $webservice: Boolean!
            $projects: [String!]
          ) {
            createPostType(
              title: $title
              key: $key
              slugs: $slugs
              templates: $templates
              projects: $projects
              categories: $categories
              singlePage: $singlePage
              hierarchy: $hierarchy
              webservice: $webservice
            ) {
              id
              key
              title
            }
          }
        `,
        variables: {
          key,
          slugs,
          title,
          templates,
          categories,
          singlePage,
          hierarchy,
          webservice,
          projects,
        },
      }),
      transformResponse: (response) => response.createPostType,
    }),
    updatePostType: builder.mutation({
      query: ({ id, title, key, slugs, templates, projects, singlePage, categories, hierarchy, webservice }) => ({
        document: gql`
          mutation updatePostType(
            $id: String!
            $title: String!
            $key: String!
            $templates: [String!]
            $projects: [String!]
            $slugs: [PostTypeSlugsInput!]!
            $categories: [PostTypeCategoryInput!]
            $singlePage: Boolean!
            $hierarchy: Boolean!
            $webservice: Boolean!
          ) {
            updatePostType(
              id: $id
              title: $title
              key: $key
              slugs: $slugs
              templates: $templates
              projects: $projects
              categories: $categories
              singlePage: $singlePage
              hierarchy: $hierarchy
              webservice: $webservice
            ) {
              id
              key
              title
              hierarchy
              webservice
              singlePage
              templates {
                id
              }
              slugs {
                value
                language {
                  slug
                }
              }
              categories {
                id
                title
                key
                icon
                image
                multi
                hierarchy
              }
            }
          }
        `,
        variables: {
          id,
          key,
          title,
          slugs,
          templates,
          singlePage,
          hierarchy,
          webservice,
          projects,
          categories,
        },
      }),
      transformResponse: (response) => response.updatePostType,
    }),
    updatePostTypeStructure: builder.mutation({
      query: ({ id, structure }) => ({
        document: gql`
          mutation updatePostTypeProperties($id: String!, $structure: Json!) {
            updatePostTypeProperties(id: $id, structure: $structure) {
              id
            }
          }
        `,
        variables: {
          id,
          structure,
        },
      }),
      transformResponse: (response) => response.updatePostTypeProperties,
    }),
    deletePostType: builder.mutation({
      query: ({ id }) => ({
        document: gql`
          mutation deletePostType($id: String!) {
            deletePostType(id: $id) {
              id
            }
          }
        `,
        variables: {
          id,
        },
      }),
      transformResponse: (response) => response.deletePostType,
    }),
    // CONTENT TYPES
    getContentTypes: builder.query({
      query: ({ take, skip, where, orderBy }) => ({
        document: gql`
          query contentTypes($take: Int, $skip: Int, $where: ContentTypeWhere, $orderBy: ContentTypeOrderBy) {
            contentTypes(take: $take, skip: $skip, where: $where, orderBy: $orderBy) {
              id
              title
            }
          }
        `,
        variables: {
          take,
          skip,
          where,
          orderBy,
        },
      }),
      transformResponse: (response) => response.contentTypes,
    }),
    getContentType: builder.query({
      query: ({ id }) => ({
        document: gql`
          query contentType($id: String!) {
            contentType(id: $id) {
              id
              key
              title
              structure
            }
          }
        `,
        variables: {
          id,
        },
      }),
      transformResponse: (response) => response.contentType,
    }),
    createContentType: builder.mutation({
      query: ({ title, key, projects }) => ({
        document: gql`
          mutation createContentType($title: String!, $key: String!, $projects: [String!]) {
            createContentType(title: $title, key: $key, projects: $projects) {
              id
              key
              title
            }
          }
        `,
        variables: {
          title,
          key,
          projects,
        },
      }),
      transformResponse: (response) => response.createContentType,
    }),
    updateContentType: builder.mutation({
      query: ({ id, title, key, projects }) => ({
        document: gql`
          mutation updateContentType($id: String!, $title: String!, $key: String!, $projects: [String!]) {
            updateContentType(id: $id, title: $title, key: $key, projects: $projects) {
              id
              key
              title
            }
          }
        `,
        variables: {
          id,
          key,
          title,
          projects,
        },
      }),
      transformResponse: (response) => response.updateContentType,
    }),
    updateContentTypeStructure: builder.mutation({
      query: ({ id, structure }) => ({
        document: gql`
          mutation updateContentTypeProperties($id: String!, $structure: Json!) {
            updateContentTypeProperties(id: $id, structure: $structure) {
              id
            }
          }
        `,
        variables: {
          id,
          structure,
        },
      }),
      transformResponse: (response) => response.updateContentTypeProperties,
    }),
    deleteContentType: builder.mutation({
      query: ({ id }) => ({
        document: gql`
          mutation deleteContentType($id: String!) {
            deleteContentType(id: $id) {
              id
            }
          }
        `,
        variables: {
          id,
        },
      }),
      transformResponse: (response) => response.deleteContentType,
    }),
  }),
});

export const { setHistory, setActiveHistory, setSection, setCustomInputs } = sectionsSlice.actions;

export const {
  useGetCustomInputsQuery,
  useAddCustomInputMutation,

  useGetSectionsQuery,
  useGetSectionQuery,
  useCreateSectionMutation,
  useUpdateSectionMutation,
  useDeleteSectionMutation,

  useGetTemplatesQuery,
  useGetTemplateQuery,
  useCreateTemplateMutation,
  useUpdateTemplateMutation,
  useDeleteTemplateMutation,

  useGetPostTypesQuery,
  useGetPostTypeQuery,
  useCreatePostTypeMutation,
  useUpdatePostTypeMutation,
  useUpdatePostTypeStructureMutation,
  useDeletePostTypeMutation,

  useGetContentTypesQuery,
  useGetContentTypeQuery,
  useCreateContentTypeMutation,
  useUpdateContentTypeMutation,
  useUpdateContentTypeStructureMutation,
  useDeleteContentTypeMutation,
} = buildersApi;

export default sectionsSlice.reducer;
