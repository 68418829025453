import { PencilIcon, PlusIcon } from "@heroicons/react/24/outline";
import Accordion from "app/components/molecules/accordion";
import ModalElement from "app/components/molecules/modals/modalElement";
import Permission from "app/components/molecules/permission";
import Form from "app/components/organisms/form";
import { i18n } from "app/i18n";
import { useTranslateElementMutation } from "app/stores/private";
import { setUnsavedChanges } from "app/stores/project";
import { cleanStructure } from "app/utils/content";
import { getFlag } from "app/utils/languages";
import { showServerError, showServerSuccess } from "app/utils/server";
import { validation } from "app/utils/validators";
import customValidator from "app/utils/validators/customValidator";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link, useLocation, useNavigate } from "react-router-dom";

const components = [
  {
    tab: "location",
    type: "Map",
    title: "coords",
    key: "coords",
    rules: [
      {
        method: customValidator.isObjectEmpty,
        validWhen: false,
        message: i18n("input.required_field"),
      },
    ],
    types: ["STORY", "EXPERIENCE", "PARTNER"],
  },
  {
    tab: "specs",
    type: "Multimedia",
    formats: ["IMAGE"],
    title: "Featured Image",
    key: "image",
    clearMode: true,
    noAlt: true,
    types: ["STORY", "EXPERIENCE", "PARTNER"],
  },
  {
    tab: "specs",
    type: "Multimedia",
    formats: ["VIDEO", "EXTERNAL_VIDEO"],
    title: "Video",
    key: "video",
    clearMode: true,
    noPoster: true,
    types: ["STORY", "EXPERIENCE"],
  },
  {
    tab: "specs",
    type: "Select",
    title: "Categorias",
    key: "categories",
    placement: "top",
    multiple: true,
    options: [],
    types: ["STORY", "EXPERIENCE", "PARTNER"],
  },
  {
    tab: "specs",
    type: "Clock",
    title: "Duração",
    key: "duration",
    types: ["EXPERIENCE"],
  },
  {
    tab: "specs",
    type: "Text",
    title: "Score",
    key: "score",
    types: ["PARTNER"],
  },
];

export default function ElementSidebar(props) {
  const { element, onSave, whenTranslateSuccedded } = props;

  // PARAMS
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { pathname } = useLocation();

  // STORE
  const { categories } = useSelector((store) => store.private);
  const { project, language, languages, sidebarChanges } = useSelector((store) => store.project);

  // STATE
  const [value, setValue] = useState(null);
  const [modal, setModal] = useState(null);
  const [structure, setStructure] = useState(components);
  const [dataChanged, setDataChanged] = useState(false);

  // REQUEST
  const [translateElement, { isLoading: isTranslating }] = useTranslateElementMutation();

  // VARS
  const type = element?.type;
  const isLoading = isTranslating;
  const classType = pathname?.split("/")[4];
  const content = language && element?.translations.find((e) => e.language.slug === language.slug);

  // GET VALUE FROM DATABASE
  useEffect(() => {
    if (element && content) {
      const value = getValue();
      setValue({ ...value });
    }
  }, [element, content, pathname]);

  // BUILD STRUCTURE FROM TYPE
  useEffect(() => {
    buildStructure();
  }, [language, type, categories]);

  // CHECKING DIFFERENT DATA
  useEffect(() => {
    const isDifferent = JSON.stringify(getValue()) !== JSON.stringify(value);
    setDataChanged(isDifferent);
  }, [value]);
  useEffect(() => {
    if (dataChanged !== sidebarChanges) dispatch(setUnsavedChanges(dataChanged));
    return () => dispatch(setUnsavedChanges(false));
  }, [dataChanged]);

  const buildStructure = () => {
    if (!language) return null;
    let temp = cleanStructure([...components]);
    temp = temp.filter((e) => e.types.includes(type));

    if (categories) {
      const index = temp.findIndex((e) => e.key === "categories");
      if (index !== -1)
        temp[index].options = categories
          .filter((e) => e.types.includes(type))
          .map((item) => ({ label: item.translations.find((e) => e.language.slug === language.slug).value || "???", value: item.id }));
    }

    setStructure([...temp]);
  };

  const getValue = () => {
    if (!element || !content) return undefined;

    let temp = {
      image: element.image,
      video: element.video,
      score: element.score,
      duration: element.duration,
      coords: {
        latitude: element.latitude,
        longitude: element.longitude,
        place: element.place?.name,
        city: element.place?.city?.name,
        district: element.place?.city?.district?.name,
      },
      categories: element?.categories?.length
        ? element.categories.map((category) => {
            return {
              value: category.id,
              label: category.translations.find((e) => e.language.slug === language.slug).value,
            };
          })
        : undefined,
    };

    return { ...temp };
  };

  const onSubmit = () => {
    validation(structure, value, (structure, validation) => {
      setStructure([...structure]);
      if (validation.isValid) onSave(undefined, value);
    });
  };

  const onClickTranslate = (language) => {
    setModal({
      type,
      isOpen: true,
      duplicate: true,
      language: language.slug,
      title: `Translate ${type.toLowerCase()}`,
      languages: element.translations.map((e) => ({ value: e.id, label: `${getFlag(e.language.slug)} ${e.language.slug.toUpperCase()}` })),
      onClose: () => setModal(null),
      onSubmit: (data) => {
        translateElement({ element: element.id, language: language.slug, title: data.title, slug: data.slug, content: data.content, auto: data.auto }).then((res) => {
          if (res?.data) {
            showServerSuccess(i18n("toast.success_update"));
            whenTranslateSuccedded();
            navigate(`/project/${project.id}/dashboard/${classType}/${element.id}/${language.slug}`);
          } else {
            showServerError(res);
          }
          setModal(null);
        });
      },
    });
  };

  return (
    <>
      <div className="h-full overflow-auto">
        <div className="flex flex-col gap-6">
          {/* TRANSLATIONS */}
          <div className="bg-white flex flex-col justify-between rounded-b-xl xl:rounded-b-md rounded-t-xl xl:rounded-t-md w-full md:min-w-[336px] xl:w-[336px] shadow-lg max-h-full overflow-hidden">
            <div className="overflow-auto max-h-full">
              <div className="relative z-10">
                <Accordion title={i18n("label.translations")} defaultOpen={true} border forceHeight bgWhite>
                  {!!languages?.length && (
                    <div>
                      <ul className="p-5">
                        {languages.map((item, index) => {
                          const exists = element?.translations?.length ? element.translations.find((e) => e.language.slug === item.language.slug) : null;
                          const current = element?.translations?.length ? element.translations.find((e) => e.language.slug === language.slug) : null;

                          return (
                            <li key={index} className="flex items-center justify-between py-3 border-b last:border-b-0">
                              {exists && current ? (
                                <>
                                  {current.id === exists.id ? (
                                    <>
                                      <span className="text-sm text-slate-900">
                                        <span className="mr-1">{getFlag(item.language.slug)}</span> {exists.title}
                                      </span>
                                    </>
                                  ) : (
                                    <>
                                      {/* ACCESS TO TRANSLATION */}
                                      <Link to={`/project/${project.id}/dashboard/${classType}/${element.id}/${item.language.slug}`}>
                                        <span className="text-sm">
                                          <span className="mr-1">{getFlag(item.language.slug)}</span> {exists.title}
                                        </span>
                                      </Link>
                                      <Link to={`/project/${project.id}/dashboard/${classType}/${element.id}/${item.language.slug}`}>
                                        <PencilIcon className="w-5" />
                                      </Link>
                                    </>
                                  )}
                                </>
                              ) : (
                                <>
                                  {/* CREATE TRANSLATION */}
                                  <span className="text-sm text-gray-400">
                                    <span className="mr-1">{getFlag(item.language.slug)}</span> {item.language.name}
                                  </span>
                                  <Permission project dev min="MANAGER">
                                    <button type="button" onClick={() => onClickTranslate(item.language)}>
                                      <PlusIcon className="w-5" />
                                    </button>
                                  </Permission>
                                </>
                              )}
                            </li>
                          );
                        })}
                      </ul>
                    </div>
                  )}
                </Accordion>
              </div>
            </div>
          </div>

          {/* LOCATION  */}
          {!!structure.filter((e) => e.tab === "location")?.length && (
            <div className="bg-white flex flex-col justify-between rounded-b-xl xl:rounded-b-md rounded-t-xl xl:rounded-t-md w-full md:min-w-[336px] xl:w-[336px] shadow-lg max-h-full overflow-hidden">
              <div className="overflow-auto max-h-full">
                <div className="relative z-10">
                  <Accordion title={i18n("label.location")} defaultOpen={true} border bgWhite>
                    <div className="p-5">
                      <Form structure={structure.filter((e) => e.tab === "location")} value={value} onChange={setValue} />
                      {dataChanged && (
                        <div className="flex justify-end mt-4">
                          <button
                            type="button"
                            onClick={onSubmit}
                            className="flex items-center relative justify-center rounded-md border border-transparent bg-blue-800 px-4 py-2 text-sm font-medium text-white shadow-sm hover:bg-gray-700 focus:outline-none  disabled:bg-gray-300 disabled:pointer-events-none"
                          >
                            {i18n("button.save")}
                          </button>
                        </div>
                      )}
                    </div>
                  </Accordion>
                </div>
              </div>
            </div>
          )}

          {/* SPECS  */}
          <div className="bg-white flex flex-col justify-between rounded-b-xl xl:rounded-b-md rounded-t-xl xl:rounded-t-md w-full md:min-w-[336px] xl:w-[336px] shadow-lg max-h-full overflow-hidden">
            <div className="overflow-auto max-h-full">
              <div className="relative z-10">
                <Accordion title={i18n("label.specs")} defaultOpen={true} border bgWhite>
                  <div className="p-5">
                    <Form structure={structure.filter((e) => e.tab === "specs")} value={value} onChange={setValue} />
                    {dataChanged && (
                      <div className="flex justify-end mt-4">
                        <button
                          type="button"
                          onClick={onSubmit}
                          className="flex items-center relative justify-center rounded-md border border-transparent bg-blue-800 px-4 py-2 text-sm font-medium text-white shadow-sm hover:bg-gray-700 focus:outline-none  disabled:bg-gray-300 disabled:pointer-events-none"
                        >
                          {i18n("button.save")}
                        </button>
                      </div>
                    )}
                  </div>
                </Accordion>
              </div>
            </div>
          </div>
        </div>
      </div>

      <ModalElement {...modal} isLoading={isLoading} />
    </>
  );
}
