import React from "react";
import { useDispatch, useSelector } from "react-redux";
import Tfoot from "./tfoot";
import Thead from "./thead";
import PropTypes from "prop-types";
import { i18n } from "app/i18n";

export default function ListTable(props) {
  const { headers = [], store = "", total, setOrder, setPage, children } = props;

  const dispatch = useDispatch();
  const { take, page, orderBy } = useSelector((state) => state[store]);

  // CALC TOTAL PAGES REQUIRED
  const totalPages = Math.ceil(total / take) || 1;

  // FUNCTION TO CHANGE ORDER
  const onClickOrder = (_val) => {
    if (!setOrder) return;
    dispatch(setOrder(_val));
  };
  // FUNCTION TO CHANGE PAGE
  const onClickPage = (_val) => {
    if (!setPage) return;
    dispatch(setPage(_val));
  };

  return (
    <div className="overflow-x-auto overflow-y-visible relative h-full pb-24">
      {children ? (
        <table className="listTable w-full text-sm text-left text-gray-500 dark:text-gray-400">
          <Thead headers={headers} setOrder={onClickOrder} orderBy={orderBy} />
          <tbody className="relative">{children}</tbody>
          <Tfoot setPage={onClickPage} total={total} current={page} pages={totalPages} cols={headers.length + 1} />
        </table>
      ) : (
        <div>
          <p className="my-10 text-sm text-gray-500 text-center">{i18n("label.no_results_to_display")}</p>
        </div>
      )}
    </div>
  );
}

ListTable.propTypes = {
  headers: PropTypes.array.isRequired,
  store: PropTypes.string,
  total: PropTypes.number,
  setOrder: PropTypes.func,
  setPage: PropTypes.func,
};
