import Icon from "app/components/atoms/icons/icon";
import IconFA from "app/components/atoms/icons/iconFA";
import Dropdown from "app/components/molecules/dropdown";
import ModalConfirm from "app/components/molecules/modals/modalConfirm";
import ModalMapCategory from "app/components/molecules/modals/modalMapCategory";
import { i18n } from "app/i18n";
import { useCreateCategoryMutation, useDeleteCategoryMutation, useGetCategoriesQuery, useUpdateCategoryMutation } from "app/stores/private";
import { showServerError, showServerSuccess } from "app/utils/server";
import { useState } from "react";
import { useSelector } from "react-redux";
import { Link, useParams } from "react-router-dom";

export default function Category() {
  // PARAMS
  const { project } = useParams();

  // STORE
  const { language, languages } = useSelector((store) => store.project);
  const { take, skip, where } = useSelector((store) => store.private);

  // STATES
  const [modal, setModal] = useState(null);
  const [modalConfirm, setModalConfirm] = useState(null);

  // QUERY
  const { data, refetch } = useGetCategoriesQuery({ take, skip, where, orderBy: { slug: "asc" } });

  // MUTATION
  const [createCategory] = useCreateCategoryMutation();
  const [updateCategory] = useUpdateCategoryMutation();
  const [deleteCategory] = useDeleteCategoryMutation();

  // FUNCTIONS
  const onCreateCategory = () => {
    setModal({
      isOpen: true,
      category: null,
      title: "Create Category",
      onClose: () => setModal(null),
      onSubmit: (data) => {
        createCategory({ ...data, language: language.slug }).then((res) => {
          if (res?.data) showServerSuccess(i18n("toast.success_create"));
          else showServerError(res);

          refetch();
          setModal(null);
        });
      },
    });
  };
  const onUpdateCategory = (item) => {
    setModal({
      languages,
      isOpen: true,
      category: item,
      title: "Update Category",
      onClose: () => setModal(null),
      onSubmit: (data) => {
        updateCategory({ id: item.id, icon: data.icon, types: data.types, translations: data.translations }).then((res) => {
          if (res?.data) showServerSuccess(i18n("toast.success_update"));
          else showServerError(res);

          refetch();
          setModal(null);
        });
      },
    });
  };
  const onDeleteCategory = (item) => {
    setModalConfirm({
      isOpen: true,
      type: "DELETE",
      mode: "INPUTS",
      onConfirm: () => {
        deleteCategory({ id: item.id }).then((res) => {
          if (res.data) showServerSuccess(i18n("toast.success_delete"));
          else showServerError(res);
          refetch();
          setModalConfirm(null);
        });
      },
      onClose: () => {
        setModalConfirm(null);
      },
    });
  };

  return (
    <div className="max-w-7xl mx-auto pt-20 px-6">
      {/* BREADCRUMBS */}
      <div className="mb-3">
        <Link to={`/project/${project}/dashboard`} className="inline-flex items-center gap-2 text-sm text-gray-400 hover:text-primary-500 transition-all duration-200 ease-linear">
          <Icon name="chevronLeft" height="h-4" width="w-4" />
          <span>{i18n("label.back")}</span>
        </Link>
      </div>

      {/* HEADER */}
      <div className="flex items-center justify-between  mb-10">
        <h1 className="text-2xl font-bold text-slate-900">Categorias</h1>
        <button type="button" onClick={onCreateCategory} className="btn-primary-outline">
          <Icon name="plus" height="h-4" width="w-4" />
          <span>{i18n("button.add_new")}</span>
        </button>
      </div>

      <div>
        <div className="overflow-x-auto overflow-y-visible relative h-full pb-24">
          <table className="listTable w-full text-sm text-left text-gray-500 dark:text-gray-400">
            <thead className="relative text-xs text-gray-700 before:bg-gray-200 before:absolute before:inset-0 before:bottom-[10px] before:rounded-lg after:leading-[10px] after:-indent-96 after:block after:content-['']">
              <tr className="relative">
                <th scope="col" className="p-6">
                  <span>{i18n("label.title")}</span>
                </th>
                <th className="p-6">
                  <span>{i18n("label.types")}</span>
                </th>
                <th></th>
              </tr>
            </thead>
            {!!data?.length ? (
              <tbody className="relative">
                {data.map((item, key) => {
                  const translation = language ? item.translations.find((e) => e.language.slug === language.slug) : null;
                  return (
                    <tr key={key}>
                      <td>
                        <div className="flex items-center gap-5">
                          {item.icon && <IconFA name={item.icon} height={5} width={5} />}
                          <span>{translation?.value || "-"}</span>
                        </div>
                      </td>
                      <td>{item?.types?.join(", ")}</td>
                      <td>
                        <Dropdown
                          icon="ellipsis"
                          options={[
                            { title: i18n("button.edit"), onClick: () => onUpdateCategory(item), icon: "edit" },
                            { title: i18n("button.delete"), onClick: () => onDeleteCategory(item), icon: "delete", delete: true },
                          ]}
                        />
                      </td>
                    </tr>
                  );
                })}
              </tbody>
            ) : (
              <tbody className="relative">
                <tr>
                  <td colSpan={3} className="!text-center">
                    {i18n("label.nothing_found")}
                  </td>
                </tr>
              </tbody>
            )}
          </table>
        </div>

        <ModalMapCategory {...modal} />
        <ModalConfirm {...modalConfirm} />
      </div>
    </div>
  );
}
